import { ActionTypes } from './dashboardcountActions';

/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description dashboard count Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store dashboard count api response  
**/

const defaultState = {
  isdashboardcount: false,
  dashboardcount: undefined,
  isdashboarduser: false,
  dashboarduser: undefined,
  isdashboardcompany: false,
  dashboardcompany: undefined,
  isdashboardteamleader: false,
  dashboardteamleader: undefined
};

const dashboardcountReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        isdashboardcount: true,
      };
    case ActionTypes.GET_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardcount: action.data,
        isdashboardcount: false,
      };
    case ActionTypes.GET_DASHBOARD_COUNT_FAILURE:
      return {
        ...state,
        isdashboardcount: false,
        error: action.error,
      };
    case ActionTypes.GET_DASHBOARD_USER_REQUEST:
      return {
        ...state,
        isdashboarduser: true,
      };
    case ActionTypes.GET_DASHBOARD_USER_SUCCESS:
      return {
        ...state,
        dashboarduser: action.data,
        isdashboarduser: false,
      };
    case ActionTypes.GET_DASHBOARD_USER_FAILURE:
      return {
        ...state,
        isdashboarduser: false,
        error: action.error,
      };
    case ActionTypes.GET_DASHBOARD_COMPANY_REQUEST:
      return {
        ...state,
        isdashboardcompany: true,
      };
    case ActionTypes.GET_DASHBOARD_COMPANY_SUCCESS:
      return {
        ...state,
        dashboardcompany: action.data,
        isdashboardcompany: false,
      };
    case ActionTypes.GET_DASHBOARD_COMPANY_FAILURE:
      return {
        ...state,
        isdashboardcompany: false,
        error: action.error,
      };
    case ActionTypes.GET_DASHBOARD_TEAMLEADER_REQUEST:
      return {
        ...state,
        isdashboardteamleader: true,
      };
    case ActionTypes.GET_DASHBOARD_TEAMLEADER_SUCCESS:
      return {
        ...state,
        dashboardteamleader: action.data,
        isdashboardteamleader: false,
      };
    case ActionTypes.GET_DASHBOARD_TEAMLEADER_FAILURE:
      return {
        ...state,
        isdashboardteamleader: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export default dashboardcountReducer;