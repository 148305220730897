/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description ActionTypes and ActionCreator Function for Dashboard count for user, companies and reviews
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_DASHBOARD_COUNT_REQUEST: 'GET_DASHBOARD_COUNT_REQUEST',
  GET_DASHBOARD_COUNT_SUCCESS: 'GET_DASHBOARD_COUNT_SUCCESS',
  GET_DASHBOARD_COUNT_FAILURE: 'GET_DASHBOARD_COUNT_FAILURE',

  GET_DASHBOARD_USER_REQUEST: 'GET_DASHBOARD_USER_REQUEST',
  GET_DASHBOARD_USER_SUCCESS: 'GET_DASHBOARD_USER_SUCCESS',
  GET_DASHBOARD_USER_FAILURE: 'GET_DASHBOARD_USER_FAILURE',

  GET_DASHBOARD_COMPANY_REQUEST: 'GET_DASHBOARD_COMPANY_REQUEST',
  GET_DASHBOARD_COMPANY_SUCCESS: 'GET_DASHBOARD_COMPANY_SUCCESS',
  GET_DASHBOARD_COMPANY_FAILURE: 'GET_DASHBOARD_COMPANY_FAILURE',

  GET_DASHBOARD_TEAMLEADER_REQUEST: 'GET_DASHBOARD_TEAMLEADER_REQUEST',
  GET_DASHBOARD_TEAMLEADER_SUCCESS: 'GET_DASHBOARD_TEAMLEADER_SUCCESS',
  GET_DASHBOARD_TEAMLEADER_FAILURE: 'GET_DASHBOARD_TEAMLEADER_FAILURE',
};

export const getdashboardcount = () => {
  return {
    type: ActionTypes.GET_DASHBOARD_COUNT_REQUEST,
  };
};

export const getdashboardusers = () => {
  return {
    type: ActionTypes.GET_DASHBOARD_USER_REQUEST,
  };
};

export const getdashboardcompany = () => {
  return {
    type: ActionTypes.GET_DASHBOARD_COMPANY_REQUEST,
  };
};

export const getdashboardteamleader = () => {
  return {
    type: ActionTypes.GET_DASHBOARD_TEAMLEADER_REQUEST,
  };
};