import { ActionTypes } from './loginActions';

/**
 * @author      Nandani.V.Patel
 * @date        21st March 2024
 * @description Login Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Login api response  
**/

const defaultState = {
  isLogin: false,
  data: undefined
};

const signInReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        data: undefined,
        isLogin: true,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLogin: false,
      };
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLogin: false,
        data: undefined,
        error: action.error,
      };
    default:
      return state;
  }
}

export default signInReducer;
