/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description ActionTypes and ActionCreator Function for team-leader-list Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  TEAM_LEADER_LISTING_REQUEST: 'TEAM_LEADER_LISTING_REQUEST',
  TEAM_LEADER_LISTING_SUCCESS: 'TEAM_LEADER_LISTING_SUCCESS',
  TEAM_LEADER_LISTING_FAILURE: 'TEAM_LEADER_LISTING_FAILURE',
};

export const teamleaderlistingRequest = (teamleaderdata) => {
  return {
    type: ActionTypes.TEAM_LEADER_LISTING_REQUEST,
    payload: teamleaderdata,
  };
};

