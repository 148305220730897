import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { navigationData } from './menu';
import { DASHBOARD } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description Dashboard-Sidebar layout.
 * @param
 * @response 
**/

const Sidebar = ({ sidemenu, handlesidebarload }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(sidemenu);
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (sidemenu) {
      setClose(false);
    }
    setShow(sidemenu);
  }, [sidemenu]);

  const handleClose = () => {
    setClose(true);
    handlesidebarload(!sidemenu)
  }

  let icon = 'ti-menu-2:before';
  let showicon = true;
  useEffect(() => {
    if (icon && icon !== undefined) {
      showicon = false;
    }
  }, [])

  return (
    <>
      <aside
        className={classNames([
          `left-sidebar ${styles.sidebar}`,
          {
            [styles.sidemenu]: show,
            [styles.hidesidebar]: ((close && !show) && !showicon)
          },
        ])}
      >
        <div>
          <div className="brand-logo d-flex align-items-center justify-content-between">
            <span className={`${'text-nowrap logo-img'} ${styles.dashboaricon}`} onClick={() => navigate(DASHBOARD)}>
              <img src="../assets/images/logos/logo.png" width={180} alt="" style={{ marginTop: '30px', height: '60px' }} />
            </span>
            <div
              className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
              id="sidebarCollapse"
              onClick={handleClose}
            >
              <i className="ti ti-x fs-8" />
            </div>
          </div>
          <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
            <ul id="sidebarnav">
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4" />
                {/* <span className="hide-menu">Home</span> */}
              </li>
              {navigationData?.map((navItem, index) => (
                <li key={index} className="sidebar-item">
                  <Link
                    to={navItem.href}
                    className={classNames("sidebar-link", {
                      active: location?.pathname === navItem?.href,
                    })}
                    aria-expanded="false"
                  >
                    <span>
                      <i className={navItem?.icon} />
                    </span>
                    <span className="hide-menu">{navItem?.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  )
}
export default Sidebar;