import {
  DASHBOARD,
  USER,
  TEAMLEADER,
  COMPANY,
  PLANCREATION,
  REVIEWSITES,
  WITHDRAWAL
} from "../../global/routes";

export const navigationData = [
  {
    title: 'Dashboard',
    href: DASHBOARD,
    icon: 'ti ti-layout-dashboard',
  },
  {
    title: 'Users',
    href: USER,
    icon: 'ti ti-users',
  },
  {
    title: 'Team Leader',
    href: TEAMLEADER,
    icon: 'ti ti-user',
  },
  {
    title: 'Company Details',
    href: COMPANY,
    icon: 'ti ti-align-box-left-middle',
  },
  {
    title: 'Plan Creation',
    href: PLANCREATION,
    icon: 'ti ti-businessplan',
  },
  {
    title: 'Review Sites',
    href: REVIEWSITES,
    icon: 'ti ti-view-360',
  },
  {
    title: 'Withdrawal Request',
    href: WITHDRAWAL,
    icon: 'ti ti-credit-card',
  },
]