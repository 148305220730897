import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './planCreationActions';
import { postRequestAPI, getRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description planCreation  Saga and handling API response.
 * @param       planCreation Request paylod
 * @response    planCreation is success or fail.  
**/

function* plancreationSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/store-discount-data', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.PLAN_CREATION_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.PLAN_CREATION_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.PLAN_CREATION_FAILURE, error: err?.response?.data });
  }
}

function* websiteSaga() {
  try {
    const response = yield call(postRequestAPI, { url: 'api/get-websites' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_WEBSITE_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_WEBSITE_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_WEBSITE_FAILURE, error: err?.response?.data });
  }
}

function* updatereviewsiteSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/update-reviewsite-price', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_REVIEW_SITE_PRICE_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.UPDATE_REVIEW_SITE_PRICE_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_REVIEW_SITE_PRICE_FAILURE, error: err?.response?.data });
  }
}

function* reviewsitediscountSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'api/admin/reviewsite-discount' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_REVIEW_SITE_DISCOUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_REVIEW_SITE_DISCOUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_REVIEW_SITE_DISCOUNT_FAILURE, error: err?.response?.data });
  }
}

function* deletereviewsitediscountSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/delete-review-discount', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_FAILURE, error: err?.response?.data });
  }
}

function* watchplanCreationRequest() {
  yield takeLatest(ActionTypes.PLAN_CREATION_REQUEST, plancreationSaga);
  yield takeLatest(ActionTypes.GET_WEBSITE_REQUEST, websiteSaga);
  yield takeLatest(ActionTypes.UPDATE_REVIEW_SITE_PRICE_REQUEST, updatereviewsiteSaga);
  yield takeLatest(ActionTypes.GET_REVIEW_SITE_DISCOUNT_REQUEST, reviewsitediscountSaga);
  yield takeLatest(ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_REQUEST, deletereviewsitediscountSaga);
}

function* planCreationSaga() {
  yield fork(watchplanCreationRequest);
}

export default planCreationSaga;