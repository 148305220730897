import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DashboardCountActions from '../dashboardCount/dashboardcountActions';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description User Dashboard.
 * @param
 * @response 
**/

const Dashboard = () => {
  const counts = useSelector((state) => state?.dashboardCounts?.dashboardcount);
  const users = useSelector((state) => state?.dashboardCounts?.dashboarduser);
  const companies = useSelector((state) => state?.dashboardCounts?.dashboardcompany);
  const teamleaders = useSelector((state) => state?.dashboardCounts?.dashboardteamleader);

  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      dispatch(DashboardCountActions.getdashboardcount());
      dispatch(DashboardCountActions.getdashboardusers());
      dispatch(DashboardCountActions.getdashboardcompany());
      dispatch(DashboardCountActions.getdashboardteamleader());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="row g-3">
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-primary-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex align-items-center justify-content-center">
                <div className="round rounded text-bg-primary d-flex align-items-center justify-content-center">
                  <i className="cc BTC text-white fs-7" title="BTC" />
                </div>
                <h3 className="mb-0 ms-3 text-center">Companies</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 fw-semibold fs-7 text-center">{counts?.companyCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-danger-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex align-items-center justify-content-center">
                <div className="round rounded text-bg-danger d-flex align-items-center justify-content-center">
                  <i className="cc ETH text-white fs-7" title="ETH" />
                </div>
                <h3 className="mb-0 ms-3 text-center">Reviews</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 fw-semibold fs-7 text-center">{counts?.reviewCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-success-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex align-items-center justify-content-center">
                <div className="round rounded text-bg-success d-flex align-items-center justify-content-center">
                  <i className="cc LTC text-white fs-7" title="LTC" />
                </div>
                <h3 className="mb-0 ms-3 text-center">Users</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 fw-semibold fs-7 text-center">{counts?.userCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card bg-warning-subtle shadow-none h-100">
            <div className="card-body p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex align-items-center justify-content-center">
                <div className="round rounded text-bg-warning d-flex align-items-center justify-content-center">
                  <i className="cc XRP text-white fs-7" title="XRP" />
                </div>
                <h3 className="mb-0 ms-3 text-center">Team Leader</h3>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3 className="mb-0 fw-semibold fs-7 text-center">{counts?.teamLeaderCount || 0}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card w-100 position-relative overflow-hidden" style={{ marginTop: '50px' }}>
        <div className="px-4 py-3 border-bottom" style={{ backgroundColor: '#5D87FF' }}>
          <h4 className="card-title mb-0" style={{ color: 'white' }}>Users</h4>
        </div>
        {(typeof users !== 'undefined' && users?.data?.length > 0) ? (
          <div className="card-body p-4">
            <div className="table-responsive mb-4 border rounded-1">
              <table className="table text-nowrap mb-0 align-middle">
                <thead className="table-info">
                  <tr>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">First Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Last Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Email</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Phone No</h6>
                    </th>
                  </tr>
                </thead>
                {users?.data?.map((user) => (
                  <tr key={user.id}>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_first_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_last_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_email}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_phone_number || '-'}</p></td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Users Not found</h5>
        )}
      </div>
      <div className="card w-100 position-relative overflow-hidden" style={{ marginTop: '50px' }}>
        <div className="px-4 py-3 border-bottom" style={{ backgroundColor: '#5D87FF' }}>
          <h4 className="card-title mb-0" style={{ color: 'white' }}>Companies</h4>
        </div>
        {(typeof companies !== 'undefined' && companies?.data?.length > 0) ? (
          <div className="card-body p-4">
            <div className="table-responsive mb-4 border rounded-1">
              <table className="table text-nowrap mb-0 align-middle">
                <thead className="table-info">
                  <tr>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Company Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">User Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Email</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Registration No</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Pincode No</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Phone No</h6>
                    </th>
                  </tr>
                </thead>
                {companies?.data?.map((user) => (
                  <tr key={user.id}>
                    <td><p className="mb-0 fw-normal fs-4">{user?.company_tbl_name || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_first_name || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_email || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.company_registration_number || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.company_pincode || '-'}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.company_tbl_mob_no || '-'}</p></td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Companies Not found</h5>
        )}
      </div>
      <div className="card w-100 position-relative overflow-hidden" style={{ marginTop: '50px' }}>
        <div className="px-4 py-3 border-bottom" style={{ backgroundColor: '#5D87FF' }}>
          <h4 className="card-title mb-0" style={{ color: 'white' }}>Team Leaders</h4>
        </div>
        {(typeof teamleaders !== 'undefined' && teamleaders?.data?.length > 0) ? (
          <div className="card-body p-4">
            <div className="table-responsive mb-4 border rounded-1">
              <table className="table text-nowrap mb-0 align-middle">
                <thead className="table-info">
                  <tr>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">First Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Last Name</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Email</h6>
                    </th>
                    <th>
                      <h6 className="fs-4 fw-semibold mb-0">Phone No</h6>
                    </th>
                  </tr>
                </thead>
                {teamleaders?.data?.map((user) => (
                  <tr key={user.id}>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_first_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_last_name}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_email}</p></td>
                    <td><p className="mb-0 fw-normal fs-4">{user?.user_phone_number || '-'}</p></td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Team leaders Not found</h5>
        )}
      </div>
    </>
  )
}
export default Dashboard;