import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import * as TeamLeaderListingActions from './teamleaderActions';
import * as UserActions from '../user/userActions';
import styles from './styles.module.scss'

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description UI for TeamLeader
 * @param
 * @response      
**/

const TeamLeader = (props) => {
  const { teamLeader, actions } = props;
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const [teamleaders, setTeamLeader] = useState('');
  const [activeStatus, setActiveStatus] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      actions.teamleaderlistingRequest({ page, perPage });
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof teamLeader?.data !== 'undefined' && !teamLeader?.isteamLeader) {
      setTeamLeader(teamLeader?.data?.data);
    }
  }, [teamLeader?.data, teamLeader?.isteamLeader]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handleUpdateStatus = (row) => {
    const currentStatus = activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.user_status; // Get the current status
    const newStatus = !currentStatus;

    setActiveStatus(prevState => ({
      ...prevState,
      [row.id]: newStatus,
    }));

    // Call the action to update user status
    dispatch(UserActions.updateUserStatusRequest({ user_id: row.id, status: newStatus }));
  }

  const columns = [
    {
      name: 'First Name',
      selector: row => row?.user_first_name,
      // selector: row => `${row?.user_first_name} ${row?.user_last_name}`,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row?.user_last_name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row?.user_email,
      sortable: true,
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <>
          <div className="form-check py-2 form-switch">
            <input
              className={` ${styles.iconStyle} ${'form-check-input'}`}
              type="checkbox"
              title='Update Status'
              id={`flexSwitchCheckDefault-${row.id}`}
              checked={activeStatus[row.id] !== undefined ? activeStatus[row.id] : row.user_status} // Use user_status as fallback
              onChange={() => handleUpdateStatus(row, activeStatus[row.id])}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Team Leader</h3>
      </div>
      <DataTable
        columns={columns}
        data={teamleaders}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={teamLeader?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  teamLeader: state.teamLeader,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(TeamLeaderListingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamLeader);