import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tokenkey } from "../../utils/auth";
import { useDispatch } from 'react-redux';
import * as LogoutActions from '../logout/logoutAction';
import { rememberMeKey, passKey, emailKey, encryptStorage } from '../../utils/auth';
import { SIGNIN } from '../../global/routes';
import styles from './styles.module.scss';
import userimg from '../../assest/user.jpg'

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description Dashboard-Header design and Logout api call.
 * @param
 * @response 
**/

const Header = ({ sidemenu, handlesidebarload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showmenu, setShowmenu] = useState(false);

  let token;
  if (localStorage.getItem(tokenkey) !== null && localStorage.getItem(tokenkey) !== '' && localStorage.getItem(tokenkey) !== 'undefined') {
    token = localStorage.getItem(tokenkey);
  }

  const handleLogOut = () => {
    localStorage.removeItem(tokenkey);
    // encryptStorage.removeItem(rememberMeKey);
    // encryptStorage.removeItem(emailKey);
    // encryptStorage.removeItem(passKey);
    navigate(SIGNIN);
    dispatch(LogoutActions.logoutRequest({ 'token': token }));
  }

  useEffect(() => {
    setShowmenu(sidemenu);
  }, [sidemenu]);

  const handlesideMenu = () => {
    handlesidebarload(!showmenu);
  }

  return (
    <>
      <header className="app-header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <ul className="navbar-nav">
            <li className={` ${styles.sidemenuicon} nav-item d-block d-xl-none`} onClick={handlesideMenu}>
              <p
                className="nav-link sidebartoggler nav-icon-hover"
                id="headerCollapse"
              >
                <i className="ti ti-menu-2" />
              </p>
            </li>
          </ul>
          <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li className="nav-item dropdown">
                <p
                  className={`nav-link nav-icon-hover ${styles.profileimg}`}
                  id="drop2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={userimg} alt="" width="35" height="35" class="rounded-circle" />
                </p>
                <div
                  className={` ${styles.dropdownStyle} dropdown-menu dropdown-menu-end dropdown-menu-animate-up`}
                  aria-labelledby="drop2"
                >
                  <div className="message-body">
                    <button
                      type='submit'
                      onClick={handleLogOut}
                      className="btn btn-outline-primary mx-3 mt-2 d-block"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}
export default Header;