import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PlanCreationActions from './planCreationActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import styles from './styles.module.scss';

const PlanCreation = (props) => {
  const { plancreation, actions } = props;
  const dispatch = useDispatch();
  const [checkedFields, setCheckedFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [websites, setWebsites] = useState('');
  const [firstInit, setFirstInit] = useState(false);

  useEffect(() => {
    if (firstInit) {
      actions.getwebsiteRequest();
      actions.getreviewsitediscountRequest();
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (plancreation?.website?.status === 'success') {
      setWebsites(plancreation?.website?.data);
    }
  }, [plancreation?.website]);

  useEffect(() => {
    if (plancreation?.reviewsitediscount?.status === 'success') {
      const discountData = plancreation.reviewsitediscount.data.length
        ? plancreation.reviewsitediscount.data.map(item => ({
          minValue: item.review_site_discount_min_value,
          maxValue: item.review_site_discount_max_value,
          discountPrice: item.review_site_discount_perecentage,
        }))
        : [{ minValue: '', maxValue: '', discountPrice: '' }]; // Default field if no data

      resetDiscounts({ discounts: discountData });
    }
  }, [plancreation?.reviewsitediscount]);

  const
    { handleSubmit: handleSubmitBasic,
      setValue,
      watch,
      register: registerBasic,
      resetField, reset: resetBasic,
      formState: { errors: errorsBasic },
      clearErrors
    } = useForm({
      defaultValues: {
        review_site_id: '',
        review_site_price: '',
      }
    });

  const selectedSiteId = watch("review_site_id");

  useEffect(() => {
    if (selectedSiteId) {
      const selectedSite = websites?.find(site => site?.id === parseInt(selectedSiteId));
      if (selectedSite) {
        setValue("review_site_price", selectedSite?.review_site_tbl_review_price); // Set default price based on selection
        clearErrors("review_site_price");
      }
    } else {
      setValue("review_site_price", "");
    }
  }, [selectedSiteId, setValue, websites]);

  const
    { control,
      register: registerDiscounts,
      reset: resetDiscounts,
      formState: { errors: errorsDiscounts },
      getValues
    } = useForm({
      defaultValues: {
        discounts: [{ minValue: '', maxValue: '', discountPrice: '' }]
      }
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  });

  const handleCheck = (index) => {
    const discountData = getValues(`discounts.${index}`);
    const newErrorMessages = {};

    // Validate required fields
    if (!discountData.minValue) {
      newErrorMessages.minValue = 'Min Count is required.';
    }
    if (!discountData.maxValue) {
      newErrorMessages.maxValue = 'Max Count is required.';
    }
    if (!discountData.discountPrice) {
      newErrorMessages.discountPrice = 'Discount Percentage is required.';
    }

    setErrorMessages(prev => ({
      ...prev,
      [index]: newErrorMessages
    }));

    // If there are no errors, call the API
    if (Object.keys(newErrorMessages).length === 0) {
      setCheckedFields(prev => {
        const updated = [...prev];
        updated[index] = true; // Mark the current field as checked
        return updated;
      });

      const payload = {
        reviewPercentage: parseFloat(discountData.discountPrice),
        minReviews: parseInt(discountData.minValue, 10),
        maxReviews: parseInt(discountData.maxValue, 10),
      };
      actions.planCreationRequest(payload);
    }
  };

  useEffect(() => {
    if (plancreation?.data?.status === 'success') {
      notification(plancreation?.data?.message, 'success');
      actions.getreviewsitediscountRequest();
      dispatch({ type: PlanCreationActions.ActionTypes.PLAN_CREATION_SUCCESS, data: undefined });
    }
    if (plancreation?.reviewSitePriceData?.status === 'success') {
      notification(plancreation?.reviewSitePriceData?.message, 'success');
      resetField('review_site_id');
      resetField('review_site_price');
      actions.getwebsiteRequest();
      dispatch({ type: PlanCreationActions.ActionTypes.UPDATE_REVIEW_SITE_PRICE_SUCCESS, data: undefined });
    }
    if (plancreation?.reviewSiteDiscount?.status === 'success') {
      notification(plancreation?.reviewSiteDiscount?.message, 'success');
      actions.getreviewsitediscountRequest();
      dispatch({ type: PlanCreationActions.ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_SUCCESS, data: undefined });
    }
    if (plancreation?.error) {
      notification(plancreation?.error?.message, 'error');
      dispatch({ type: PlanCreationActions.ActionTypes.PLAN_CREATION_FAILURE, error: undefined });
    }
  }, [plancreation?.data, plancreation?.reviewSitePriceData, plancreation?.error, plancreation?.reviewSiteDiscount]);

  const onSubmitBasic = (data) => {
    const payload = {
      review_site_price: parseFloat(data.review_site_price),
      review_site_id: parseInt(data.review_site_id)
    };
    actions.updatereviewsitepriceRequest(payload);
  };

  const isRemoveDisabled = (index) => {
    const discount = getValues(`discounts.${index}`);
    const isFieldEmpty = !discount?.minValue && !discount?.maxValue && !discount?.discountPrice;
    return fields?.length === 1 && isFieldEmpty;
  };

  return (
    <form onSubmit={handleSubmitBasic(onSubmitBasic)}>
      {/* Basic Info Section */}
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Plan Creation</h3>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className="form-floating mb-3">
            <select
              className="form-select"
              {...registerBasic("review_site_id", {
                required: 'Website is required.'
              })}
            >
              <option value="">Select Website</option>
              {websites && websites.map((site) => (
                <option key={site?.id} value={site?.id}>
                  {site?.review_site_tbl_name}
                </option>
              ))}
            </select>
            <label>Review Websites</label>
            {errorsBasic?.review_site_id && <span className={styles.errormsg}>{errorsBasic.review_site_id.message}</span>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Price Per Review"
              {...registerBasic("review_site_price", {
                required: 'Price Per Review is required.',
                validate: {
                  positive: value => value > 0 || 'Price Per Review must be greater than zero.',
                  maxLimit: value => value < 1000000 || 'Price Per Review must be less than 1,000,000.'
                }
              })}
            />
            <label>Price Per Review</label>
            {errorsBasic?.review_site_price && <span className={styles.errormsg}>{errorsBasic.review_site_price.message}</span>}
          </div>
        </div>
        <div className="col-12">
          <div className="d-md-flex align-items-center">
            <div className="ms-auto mt-3">
              <button
                type="button"
                className="btn btn-primary hstack gap-6"
                onClick={handleSubmitBasic(onSubmitBasic)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Discount Section */}
      <div className="card-header text-bg-primary my-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Discount</h3>
      </div>
      <div className="row">
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <div className="col-md-4">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Min Count"
                  {...registerDiscounts(`discounts.${index}.minValue`, {
                    required: true
                  })}
                />
                <label>Min Count</label>
                {errorMessages[index]?.minValue && (
                  <span className={styles.errormsg}>{errorMessages[index].minValue}</span>
                )}
                {errorsDiscounts?.discounts?.[index]?.minValue && (
                  <span className={styles.errormsg}>{errorsDiscounts.discounts[index].minValue.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max Count"
                  {...registerDiscounts(`discounts.${index}.maxValue`, {
                    required: true
                  })}
                />
                <label>Max Count</label>
                {errorMessages[index]?.maxValue && (
                  <span className={styles.errormsg}>{errorMessages[index].maxValue}</span>
                )}
                {errorsDiscounts?.discounts?.[index]?.maxValue && (
                  <span className={styles.errormsg}>{errorsDiscounts.discounts[index].maxValue.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-floating mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Discount Price"
                  {...registerDiscounts(`discounts.${index}.discountPrice`, {
                    required: true
                  })}
                />
                <label>%</label>
                {errorMessages[index]?.discountPrice && (
                  <span className={styles.errormsg}>{errorMessages[index].discountPrice}</span>
                )}
                {errorsDiscounts?.discounts?.[index]?.discountPrice && (
                  <span className={styles.errormsg}>{errorsDiscounts.discounts[index].discountPrice.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-between align-items-center mb-3">
              <button
                type="button"
                title='Save'
                className="btn btn-primary"
                onClick={() => handleCheck(index)}
              >
                ✔
              </button>
              <button
                type="button"
                title='Add'
                className="btn btn-primary"
                onClick={() => append({ minValue: '', maxValue: '', discountPrice: '' })}
              >
                +
              </button>
              <button
                type="button"
                title='Delete'
                className="btn btn-danger"
                // onClick={() => remove(index)}
                onClick={() => {
                  const discountData = plancreation.reviewsitediscount.data[index]; // Get the discount data by index
                  if (discountData) {
                    const discountId = discountData?.id; // Fetch the discount ID
                    actions.deletereviewsitediscountRequest({ id: discountId }); // Pass the ID to the delete action
                  }
                  remove(index); // Remove the field from the form
                }}
                disabled={isRemoveDisabled(index)}
              >
                -
              </button>
            </div>
          </React.Fragment>
        ))}
      </div>
      <NotificationContainer />
    </form>
  );
};

const mapStateToProps = (state) => ({
  plancreation: state.plancreation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PlanCreationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanCreation);
