import { ActionTypes } from './planCreationActions';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description plancreation Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Plancreation api response  
**/

const defaultState = {
  isPlancreation: false,
  iswebsite: false,
  isupdatereviewsite: false,
  isreviewsitediscount: false,
  isdeletediscount: false,
};

const planCreationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.PLAN_CREATION_REQUEST:
      return {
        ...state,
        isPlancreation: true,
      };
    case ActionTypes.PLAN_CREATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isPlancreation: false,
      };
    case ActionTypes.PLAN_CREATION_FAILURE:
      return {
        ...state,
        isPlancreation: false,
        error: action.error,
      };
    case ActionTypes.GET_WEBSITE_REQUEST:
      return {
        ...state,
        iswebsite: true,
      };
    case ActionTypes.GET_WEBSITE_SUCCESS:
      return {
        ...state,
        website: action.data,
        iswebsite: false,
      };
    case ActionTypes.GET_WEBSITE_FAILURE:
      return {
        ...state,
        iswebsite: false,
        error: action.error,
      };
    case ActionTypes.UPDATE_REVIEW_SITE_PRICE_REQUEST:
      return {
        ...state,
        isupdatereviewsite: true,
      };
    case ActionTypes.UPDATE_REVIEW_SITE_PRICE_SUCCESS:
      return {
        ...state,
        reviewSitePriceData: action.data,
        isupdatereviewsite: false,
      };
    case ActionTypes.UPDATE_REVIEW_SITE_PRICE_FAILURE:
      return {
        ...state,
        isupdatereviewsite: false,
        error: action.error,
      };
    case ActionTypes.GET_REVIEW_SITE_DISCOUNT_REQUEST:
      return {
        ...state,
        isreviewsitediscount: true,
      };
    case ActionTypes.GET_REVIEW_SITE_DISCOUNT_SUCCESS:
      return {
        ...state,
        reviewsitediscount: action.data,
        isreviewsitediscount: false,
      };
    case ActionTypes.GET_REVIEW_SITE_DISCOUNT_FAILURE:
      return {
        ...state,
        isreviewsitediscount: false,
        error: action.error,
      };
    case ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_REQUEST:
      return {
        ...state,
        isdeletediscount: true,
      };
    case ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_SUCCESS:
      return {
        ...state,
        reviewSiteDiscount: action.data,
        isdeletediscount: false,
      };
    case ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_FAILURE:
      return {
        ...state,
        isdeletediscount: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default planCreationReducer;