import { ActionTypes } from './reviewsitedetailsActions';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description reviewsitedetails Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store reviewsitedetails api response  
**/

const defaultState = {
  isReviewSiteDetail: false,
  isCompanyUserReview: false,
};

const reviewsiteDetailsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REVIEWSITES_DETAILS_REQUEST:
      return {
        ...state,
        isReviewSiteDetail: true,
      };
    case ActionTypes.REVIEWSITES_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isReviewSiteDetail: false,
      };
    case ActionTypes.REVIEWSITES_DETAILS_FAILURE:
      return {
        ...state,
        isReviewSiteDetail: false,
        error: action.error,
      };
    case ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_REQUEST:
      return {
        ...state,
        isCompanyUserReview: true,
      };
    case ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userdata: action.data,
        isCompanyUserReview: false,
      };
    case ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_FAILURE:
      return {
        ...state,
        isCompanyUserReview: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default reviewsiteDetailsReducer;