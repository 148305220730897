import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './teamleaderActions';
import { postRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description Registration  Saga and handling API response.
 * @param       team-leader-listing Request paylod
 * @response    team-leader listing  is success or fail.  
**/

function* TeamLeaderlistSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/team-leaders', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.TEAM_LEADER_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.TEAM_LEADER_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.TEAM_LEADER_LISTING_FAILURE, error: err?.response?.data });
  }
}

function* watchTeamLeaderListingRequest() {
  yield takeLatest(ActionTypes.TEAM_LEADER_LISTING_REQUEST, TeamLeaderlistSaga);
}

function* teamleaderListingSaga() {
  yield fork(watchTeamLeaderListingRequest);
}

export default teamleaderListingSaga;