/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description ActionTypes and ActionCreator Function for reviewsite-details Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  REVIEWSITES_DETAILS_REQUEST: 'REVIEWSITES_DETAILS_REQUEST',
  REVIEWSITES_DETAILS_SUCCESS: 'REVIEWSITES_DETAILS_SUCCESS',
  REVIEWSITES_DETAILS_FAILURE: 'REVIEWSITES_DETAILS_FAILURE',

  REVIEW_SITE_WISE_USER_DETAILS_REQUEST: 'REVIEW_SITE_WISE_USER_DETAILS_REQUEST',
  REVIEW_SITE_WISE_USER_DETAILS_SUCCESS: 'REVIEW_SITE_WISE_USER_DETAILS_SUCCESS',
  REVIEW_SITE_WISE_USER_DETAILS_FAILURE: 'REVIEW_SITE_WISE_USER_DETAILS_FAILURE',

};

export const reviewsitedetailsRequest = (review_site_id) => {
  return {
    type: ActionTypes.REVIEWSITES_DETAILS_REQUEST,
    payload: review_site_id,
  };
};

export const reviewsitewiseuserRequest = (reviewsiteData) => {
  return {
    type: ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_REQUEST,
    payload: reviewsiteData,
  };
};