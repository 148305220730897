/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description ActionTypes and ActionCreator Function for reviewsite-list Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  REVIEWSITES_LISTING_REQUEST: 'REVIEWSITES_LISTING_REQUEST',
  REVIEWSITES_LISTING_SUCCESS: 'REVIEWSITES_LISTING_SUCCESS',
  REVIEWSITES_LISTING_FAILURE: 'REVIEWSITES_LISTING_FAILURE',
};

export const reviewsitelistingRequest = (reviewsitelistData) => {
  return {
    type: ActionTypes.REVIEWSITES_LISTING_REQUEST,
    payload: reviewsitelistData,
  };
};

