/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description ActionTypes and ActionCreator Function for User Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  UPDATE_USER_STATUS_REQUEST: 'UPDATE_USER_STATUS_REQUEST',
  UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
  UPDATE_USER_STATUS_FAILURE: 'UPDATE_USER_STATUS_FAILURE',
};

export const getuserRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_USER_REQUEST,
    payload: paginationData,
  };
};

export const deleteUserRequest = (userId) => {
  return {
    type: ActionTypes.DELETE_USER_REQUEST,
    payload: userId,
  };
};

export const updateUserStatusRequest = (userid) => {
  return {
    type: ActionTypes.UPDATE_USER_STATUS_REQUEST,
    payload: userid,
  };
};