import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './userActions';
import { postRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description User  Saga and handling API response.
 * @param       
 * @response    list of user in system.  
**/

function* UserSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/users', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_USER_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_USER_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_USER_FAILURE, error: err });
  }
}

function* deleteUserSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: '', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.DELETE_USER_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.DELETE_USER_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.DELETE_USER_FAILURE, error: err?.response?.data });
  }
}

function* userStatusUpdateSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/update-user-status', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_USER_STATUS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.UPDATE_USER_STATUS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_USER_STATUS_FAILURE, error: err?.response?.data });
  }
}

function* watchUserRequest() {
  yield takeLatest(ActionTypes.GET_USER_REQUEST, UserSaga);
  yield takeLatest(ActionTypes.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(ActionTypes.UPDATE_USER_STATUS_REQUEST, userStatusUpdateSaga);
}

function* userSaga() {
  yield fork(watchUserRequest);
}

export default userSaga;