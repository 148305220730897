import { all, fork } from 'redux-saga/effects';
import signInSaga from '../../component/login/loginSaga';
import logoutSaga from '../../component/logout/logoutSaga';
import userSaga from '../../component/user/userSaga';
import companyListingSaga from '../../component/company/companylistSaga';
import teamleaderListingSaga from '../../component/teamLeader/teamleaderSaga';
import dashboardCountSaga from '../../component/dashboardCount/dashboardcountSaga';
import reviewsiteListingSaga from '../../component/reviewsites/ReviewSitesSaga';
import reviewsiteDetailsSaga from '../../component/reviewsitedetails/reviewsitedetailsSaga';
import planCreationSaga from '../../component/planCreation/planCreationSaga';
import WithdrawalSaga from '../../component/withdrawal/withdrawalSaga';

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description rootSaga function which contains all react-application saga.
 * @param       
 * @response      
**/

export function* rootSaga() {
  yield all([
    fork(signInSaga),
    fork(logoutSaga),
    fork(userSaga),
    fork(companyListingSaga),
    fork(teamleaderListingSaga),
    fork(dashboardCountSaga),
    fork(reviewsiteListingSaga),
    fork(reviewsiteDetailsSaga),
    fork(planCreationSaga),
    fork(WithdrawalSaga),
  ]);
}