/**
 * @author      Nandani.V.Patel
 * @date        21st March 2024
 * @description ActionTypes and ActionCreator Function for User Login Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
};

export const loginRequest = (loginData) => {
  return {
    type: ActionTypes.LOGIN_REQUEST,
    payload: loginData,
  };
};