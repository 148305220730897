import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanydetailsActions from '../company/companylistActions';
import { COMPANY } from '../../global/routes';
import { NotificationContainer } from '../notificationContainer';
import { notification } from '../notificationContainer/notification';

/**
 * @author      Nandani.V.Patel
 * @date        23 Oct 2024
 * @description UI for Company Details
 * @param
 * @response      
**/

const CompanyDetails = () => {
  const { register } = useForm();
  const companylist = useSelector((state) => state?.companylist);
  const [companyInfo, setCompanyInfo] = useState({});
  const [firstInit, setFirstInit] = useState(false);
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const company_id = location.id ? location.id : '';

  useEffect(() => {
    if (firstInit) {
      dispatch(CompanydetailsActions.companydetailsRequest({ company_id: company_id }));
      setFirstInit(false);
    }
  }, [firstInit, dispatch, company_id]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (companylist?.data?.status === 'success') {
      setCompanyInfo(companylist?.data?.data);
    }
    if (companylist?.data === undefined && companylist.error !== undefined) {
      notification(companylist?.error?.message, 'error');
    }
    dispatch({ type: CompanydetailsActions.ActionTypes.COMPANY_DETAILS_FAILURE, error: undefined });
  }, [companylist?.data, companylist?.error]);

  return (
    <>
      <Link to={COMPANY}> {'<'} Back </Link>
      <div className="card-header text-bg-primary mb-3 mt-2" style={{ cursor: 'pointer' }}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>{companyInfo?.company_tbl_name} Company</h3>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Name</label>
          <input
            className="form-control"
            {...register("company_tbl_name")}
            value={companyInfo?.company_tbl_name || ''}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Category Name</label>
          <input
            className="form-control"
            {...register("category_name")}
            value={companyInfo?.category_name || ''}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Country</label>
          <input
            className="form-control"
            {...register("company_tbl_country")}
            value={companyInfo?.country_name || ''}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Mobile Number</label>
          <input
            className="form-control"
            {...register("company_tbl_mob_no")}
            value={companyInfo?.company_tbl_mob_no || ''}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Registration Number</label>
          <input
            className="form-control"
            {...register("company_registration_number")}
            value={companyInfo?.company_registration_number || ''}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Pincode</label>
          <input
            className="form-control"
            {...register("company_pincode")}
            value={companyInfo?.company_pincode || ''}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Company Address</label>
          <textarea
            className="form-control"
            {...register("company_tbl_addr")}
            value={companyInfo?.company_tbl_addr || ''}
            disabled
            rows={3}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-label">Company Description</label>
          <textarea
            className="form-control"
            {...register("company_tbl_description")}
            value={companyInfo?.company_tbl_description || ''}
            disabled
            rows={3}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Established Date</label>
          <input
            className="form-control"
            {...register("company_tbl_established_date")}
            value={companyInfo?.company_tbl_established_date || ''}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Website URL</label>
          <input
            className="form-control"
            {...register("company_tbl_website")}
            value={companyInfo?.company_tbl_website || ''}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Contact Email</label>
          <input
            className="form-control"
            {...register("company_contact_email")}
            value={companyInfo?.company_contact_email || ''}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Company Contact Person Name</label>
          <input
            className="form-control"
            {...register("company_contact_person_name")}
            value={companyInfo?.company_contact_person_name || ''}
            disabled
          />
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default CompanyDetails;
