let baseUrl = "";

switch (window.location.hostname) {
  case "localhost":
  case "192.168.2.45":
  case "192.168.2.51":
    // LOCAL
    baseUrl = "http://192.168.2.44:8003/";
    break;

  case "rmp.invobilling.com":
  case "rmp-admin.invobilling.com":
    // stage
    baseUrl = "https://rmp-api.invobilling.com/";
    break;
}

export const BACKEND_API = baseUrl;
