import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './ReviewSitesActions';
import { postRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description reviewsite  Saga and handling API response.
 * @param       reviewsite-listing Request paylod
 * @response    reviewsite listing  is success or fail.  
**/

function* reviewsitelistSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/get-websites', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REVIEWSITES_LISTING_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REVIEWSITES_LISTING_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REVIEWSITES_LISTING_FAILURE, error: err?.response?.data });
  }
}

function* watchReviewSiteListingRequest() {
  yield takeLatest(ActionTypes.REVIEWSITES_LISTING_REQUEST, reviewsitelistSaga);
}

function* reviewsiteListingSaga() {
  yield fork(watchReviewSiteListingRequest);
}

export default reviewsiteListingSaga;