import React, { useState } from 'react';
import Header from '../dashboardheader';
import Sidebar from '../dashboardsidebar';
import DashboardBody from '../dashboardbody';
import { Outlet } from 'react-router-dom';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description LayoutWrapper Component it contains comman UI for dashboard and render child component.
 * @param
 * @response 
**/

const LayoutWrapper = () => {
  const [sidemenu, setSideMenu] = useState(false);

  const sidebarload = (value) => {
    setSideMenu(value);
  };

  return (
    <>
      <div
        className="page-wrapper"
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <Sidebar
          sidemenu={sidemenu}
          handlesidebarload={sidebarload}
        />
        <div className='body-wrapper'>
          <Header
            sidemenu={sidemenu}
            handlesidebarload={sidebarload}
          />
          <DashboardBody><Outlet /></DashboardBody>
        </div>
      </div>
    </>
  )
}
export default LayoutWrapper;