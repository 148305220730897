import axios from "axios";
import { tokenkey } from "../utils/auth";
import { SIGNIN } from '../global/routes';
import { BACKEND_API } from "./url";

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description comman function of axios api call like post, get methods.
 * @param       api url and  req params
 * @response      
**/

function reqHeaders() {
  if (localStorage.getItem(tokenkey) !== null && localStorage.getItem(tokenkey) !== '' && localStorage.getItem(tokenkey) !== 'undefined') {
    const accesstoken = localStorage.getItem(tokenkey);
    axios.defaults.headers.common['Authorization'] = `Bearer ${accesstoken}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  }
}

function setHttpHeaderMultipartToken() {
  if (localStorage.getItem(tokenkey) !== null && localStorage.getItem(tokenkey) !== '' && localStorage.getItem(tokenkey) !== 'undefined') {
    const accesstoken = localStorage.getItem(tokenkey);
    axios.defaults.headers.common['Authorization'] = `Bearer ${accesstoken}`;
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  }
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.status === 401) {
      localStorage.removeItem(tokenkey);
      setTimeout(() => {
        window.location.href = SIGNIN;
      }, 10);
    }
    return Promise.reject(error);
  }
);

export function postRequestAPI({ url = '', data = {} }) {
  reqHeaders();
  return axios.post(`${BACKEND_API}${url}`, data);
}

export function getRequestAPI({ url = '', params = {} }) {
  reqHeaders();
  return axios.get(`${BACKEND_API}${url}`, params);
}

export function postWithoutHeaderRequestAPI({ url = '', data = {} }) {
  return  axios.post(`${BACKEND_API}${url}`, data);
}

export function getWithoutHeaderRequestAPI({ url = '', params = {} }) {
  return axios.get(`${BACKEND_API}${url}`, params);
}

export function postFileRequestAPI({ url = '', data = {} }) {
  setHttpHeaderMultipartToken();
  return axios.post(`${BACKEND_API}${url}`, data);
}
