import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import * as ReviewsiteActions from '../reviewsitedetails/reviewsitedetailsActions';
import { REVIEWSITES } from '../../global/routes';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        13 Nov 2024
 * @description Review-site Company user
 * @param
 * @response 
**/

const ReviewsiteCompanyUser = () => {
  const reviewsitedetails = useSelector((state) => state?.reviewsitedetails);
  const dispatch = useDispatch();

  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [reviewsitesuserdetail, setReviewsiteuserdetail] = useState('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reviewsiteId = queryParams.get('id');
  const companyId = queryParams.get('companyid');

  useEffect(() => {
    if (firstInit) {
      dispatch(ReviewsiteActions.reviewsitewiseuserRequest({ company_id: companyId, page, per_page: perPage, review_site_id: reviewsiteId }));
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: 'Full Name',
      selector: row => `${row?.user_first_name} ${row?.user_last_name}`,
      sortable: true,
      // minWidth: '290px',
      // maxWidth: '100px',
    },
    {
      name: 'Completed Review',
      selector: row => row?.review_campaign_tbl_completed_reviews || '-',
    },
    {
      name: 'Purched Review',
      selector: row => row?.review_campaign_tbl_purchased_reviews || '-',
    },
    {
      name: 'Reviewsite Url',
      selector: row => row?.review_site_tbl_url || '-',
      sortable: true,
    },
  ];

  useEffect(() => {
    if (typeof reviewsitedetails?.userdata !== 'undefined' && !reviewsitedetails?.isCompanyUserReview) {
      setReviewsiteuserdetail(reviewsitedetails?.userdata?.data);
    }
    dispatch({ type: ReviewsiteActions.ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_SUCCESS, userdata: undefined });
  }, [reviewsitedetails?.userdata, reviewsitedetails?.isCompanyUserReview]);

  return (
    <>
      <Link to={REVIEWSITES}> {'<'} Back </Link>
      <div className={`${'card-header text-bg-primary mb-3 mt-2'} ${styles.headingStyle}`}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>User Details</h3>
      </div>
      <DataTable
        columns={columns}
        data={reviewsitesuserdetail}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={reviewsitedetails?.userdata?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
    </>
  )
}

export default ReviewsiteCompanyUser;