import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../store/effect/index';
import { rootReducer } from './reducer/index';

const sagaMiddleware = createSagaMiddleware();

/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description global state of a application is store in an object
 * @param       
 * @response      
**/

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);