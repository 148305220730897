import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './loginActions';
import { postRequestAPI } from '../../global/api';
import { tokenkey } from '../../utils/auth';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description Login Saga and handling API response.
 * @param       login-user Request paylod
 * @response    user login  is success or fail.  
**/

function* loginUserSaga(action) {
    try {
        const response = yield call(postRequestAPI, { url: 'api/admin/login', data: action.payload });
        if (response?.data?.status === 'success') {
            localStorage.setItem(tokenkey, response?.data?.access_token);
            yield put({ type: ActionTypes.LOGIN_SUCCESS, data: response.data });
        } else {
            yield put({ type: ActionTypes.LOGIN_FAILURE, error: response.data });
        }
    } catch (err) {
        yield put({ type: ActionTypes.LOGIN_FAILURE, error: err?.response?.data });
    }
}

function* watchLoginRequest() {
    yield takeLatest(ActionTypes.LOGIN_REQUEST, loginUserSaga);
}

function* signInSaga() {
    yield fork(watchLoginRequest);
}

export default signInSaga;