import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './dashboardcountActions';
import { getRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description Dashboard count Saga and handling API response.
 * @param       
 * @response    Dashboard count is success or fail.  
**/

function* dashboardcountSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'api/count' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_DASHBOARD_COUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_DASHBOARD_COUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_DASHBOARD_COUNT_FAILURE, error: err?.response?.data });
  }
}

function* dashboarduserSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'api/admin/latest-users' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_DASHBOARD_USER_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_DASHBOARD_USER_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_DASHBOARD_USER_FAILURE, error: err?.response?.data });
  }
}

function* dashboardcomapnySaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'api/admin/latest-company' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_DASHBOARD_COMPANY_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_DASHBOARD_COMPANY_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_DASHBOARD_COMPANY_FAILURE, error: err?.response?.data });
  }
}

function* dashboardteamleaderSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'api/admin/latest-team-leaders' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_DASHBOARD_TEAMLEADER_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_DASHBOARD_TEAMLEADER_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_DASHBOARD_TEAMLEADER_FAILURE, error: err?.response?.data });
  }
}

function* watchDashboardCountRequest() {
  yield takeLatest(ActionTypes.GET_DASHBOARD_COUNT_REQUEST, dashboardcountSaga);
  yield takeLatest(ActionTypes.GET_DASHBOARD_USER_REQUEST, dashboarduserSaga);
  yield takeLatest(ActionTypes.GET_DASHBOARD_COMPANY_REQUEST, dashboardcomapnySaga);
  yield takeLatest(ActionTypes.GET_DASHBOARD_TEAMLEADER_REQUEST, dashboardteamleaderSaga);
}

function* dashboardCountSaga() {
  yield fork(watchDashboardCountRequest);
}

export default dashboardCountSaga;