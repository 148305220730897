/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description Defines the Routes for all React Components.
 * @param
 * @response 
**/

export const SIGNIN = '/login';
export const NOTFOUND = '/404';
export const DASHBOARD = '/dashboard';
export const USER = '/users';
export const TEAMLEADER = '/teamleader';
export const COMPANY = '/company';
export const COMPANYID = '/:id';
export const PLANCREATION = '/planCreation';
export const REVIEWSITES = '/reviewSites';
export const REVIEWSITESID = '/:id';
export const WITHDRAWAL = '/Withdrawal';
