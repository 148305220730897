/**
 * @author      Nandani.V.Patel
 * @date        04 Oct 2024
 * @description its defines isAuth() fn ,it check user token set in localstorage or not.
 * @param
 * @response 
**/

// this key are into the Encrypted format for store into localStorage
import { EncryptStorage } from 'encrypt-storage';

const secretKey = '2b4530da8afsdca21';

export const tokenkey = '/fce29ba1622f6d47b4fb6e50ebeca21dc412a1fe623d';
export const rememberMeKey = 'e833afdd2b4530da8af4166d0d779';
export const emailKey = 'cbea354fef42911f26cb383839ec6f70072';
export const passKey = 'df28f8cc683056cb8b04f09e22b8ed8e71';

export function isAuth() {
  const tokenExpires = localStorage.getItem(tokenkey);
  if (tokenExpires !== undefined && tokenExpires !== null && tokenExpires !== '') {
    return true;
  }
  return false;
}

export const encryptStorage = new EncryptStorage(secretKey);