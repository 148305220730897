import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import * as ReviewDetailsActions from './reviewsitedetailsActions';
import { REVIEWSITES } from '../../global/routes';
import { NotificationContainer } from '../notificationContainer';
import { notification } from '../notificationContainer/notification';
import styles from './styles.module.scss';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description UI for Reviewsite details.
 * @param
 * @response      
**/

const ReviewsiteDetails = (props) => {
  const { reviewsitedetails, actions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstInit, setFirstInit] = useState(false);
  const [reviewsitesdetail, setReviewsitesdetail] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const location = useLocation();
  // const reviewsite_id = location.id ? location.id : '';
  // const [companyName, companyId] = location.id ? location.id.split('=') : [null, null];
  // const reviewsite_id = companyId ? parseInt(companyId, 10) : null;
  const queryParams = new URLSearchParams(location.search);
  const reviewsiteName = queryParams.get('reviewsite'); // For query parameter
  const reviewsite_id = queryParams.get('id');

  useEffect(() => {
    if (firstInit) {
      actions.reviewsitedetailsRequest({ review_site_id: reviewsite_id, page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof reviewsitedetails?.data !== 'undefined' && !reviewsitedetails?.isReviewSiteDetail) {
      setReviewsitesdetail(reviewsitedetails?.data?.data);
    }
    dispatch({ type: ReviewDetailsActions.ActionTypes.REVIEWSITES_DETAILS_SUCCESS, data: undefined });
  }, [reviewsitedetails?.data, reviewsitedetails?.isReviewSiteDetail]);

  useEffect(() => {
    if (Array?.isArray(reviewsitedetails?.error?.data) && reviewsitedetails?.error?.status === 'failed') {
      notification(reviewsitedetails?.error?.message, 'error');
      dispatch({ type: ReviewDetailsActions.ActionTypes.REVIEWSITES_DETAILS_FAILURE, error: undefined });
    }
    dispatch({ type: ReviewDetailsActions.ActionTypes.REVIEWSITES_DETAILS_FAILURE, error: undefined });
  }, [reviewsitedetails?.error]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: 'Company Name',
      selector: row => row?.company_tbl_name,
      sortable: true,
      // minWidth: '290px',
      // maxWidth: '100px',
    },
    {
      name: 'Category name',
      selector: row => row?.category_name || '-',
      sortable: true,
      // minWidth: '301px',
      // maxWidth: '100px',
    },
    {
      name: 'Location',
      selector: row => row?.country_name || '-',
      // minWidth: '280px',
      // maxWidth: '80px',
    },
    // {
    //   // ...(reviewsiteName === 'Glassdoor' && companyId === 14 ? [{
    //   name: 'Type',
    //   center: 'yes',
    //   cell: (row) => (
    //     <>
    //       <div className="form-check py-2">
    //         <button
    //           type="button"
    //           title=''
    //           className="btn bg-primary-subtle text-primary"
    //           style={{ cursor: 'text' }}
    //         >
    //           Manual Review
    //         </button>
    //       </div>
    //     </>
    //   ),
    //   // }] : []),
    // },
    // {
    //   name: "Action",
    //   center: 'yes',
    //   minWidth: '280px',
    //   maxWidth: '80px',
    //   cell: (row) => (
    //     <>
    //       <div className="form-check py-2">
    //         <button
    //           type="button"
    //           title='View'
    //           className="btn bg-primary-subtle text-primary "
    //           onClick={() => {
    //             navigate(`${REVIEWSITES}?companyid=${row.id}&id=${reviewsite_id}`); // Construct the URL
    //           }}
    //         >
    //           User list
    //         </button>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  // const firstCompanyName = reviewsitesdetail?.length > 0 ? reviewsitesdetail[0]?.review_site_tbl_name : 'Review Site Details';

  return (
    <>
      <Link to={REVIEWSITES}> {'<'} Back </Link>
      <div className={`${'card-header text-bg-primary mb-3 mt-2'} ${styles.headingStyle}`}>
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>{reviewsiteName} - Companies</h3>
      </div>
      <DataTable
        columns={columns}
        data={reviewsitesdetail}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={reviewsitedetails?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
      <NotificationContainer />
    </>
  )
}

const mapStateToProps = (state) => ({
  reviewsitedetails: state.reviewsitedetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ReviewDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsiteDetails);