/**
 * @author      Nandani.V.Patel
 * @date        12 Nov 2024
 * @description ActionTypes and ActionCreator Function for Withdrawal.
 * @param
 * @response      
**/

export const ActionTypes = {

  GET_WITHDRAWAL_REQUEST: 'GET_WITHDRAWAL_REQUEST',
  GET_WITHDRAWAL_SUCCESS: 'GET_WITHDRAWAL_SUCCESS',
  GET_WITHDRAWAL_FAILURE: 'GET_WITHDRAWAL_FAILURE',

};

export const getWithdrawalRequest = (paginationData) => {
  return {
    type: ActionTypes.GET_WITHDRAWAL_REQUEST,
    payload: paginationData,
  };
};
