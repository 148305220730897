import React, { useState, useMemo } from 'react';
import { useForm } from "react-hook-form";
import { useDropzone } from 'react-dropzone';

/**
 * @author      Nandani.V.Patel
 * @date        12 th Oct 2024
 * @description UI Implementation of Withdrawal Modal
 * @param
 * @response 
**/

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // padding: '20px',
  justifyContent: 'center',
  height: '200px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'rgb(93, 135, 255)',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ImageModal = ({ onClose, imageSrc }) => {
  const { handleSubmit } = useForm();
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setError('');
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const onSubmit = (data) => {
    if (files.length === 0) {
      setError('Please select an image');
    } else {
      // Submit the form or handle success action here
      console.log('Form submitted with image:', files[0]);
      // onClose();
    }
  }

  const thumbs = files.map(file => (
    <div key={file.name} className="thumb">
      <div
        className="thumb-inner"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
      >
        <img
          src={file.preview}
          alt={file.name}
          style={{ width: '90%', height: '50%' }}
        />
      </div>
    </div>
  ));
  return (
    <>
      <div
        className="modal fade show modal-lg"
        id="exampleModal"
        tabIndex={-1}
        style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          >
            <div className="modal-header" style={{ backgroundColor: 'rgb(93, 135, 255)' }}>
              <h5 className="modal-title" id="exampleModalLabel" style={{ color: 'white' }}>
                Approve Request
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="details-form-content pt-0 pb-0 mb-0">
                <div className="form-content" style={{ padding: '10px' }}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className='col-xl-12 col-md-12 mb-6'>
                        <label style={{ fontWeight: '500', fontSize: 'small' }}>Upload Image</label>
                      </div>
                      <div className="form-group" style={{ cursor: 'pointer' }}>
                        <div {...getRootProps({ style })}>
                          <input {...getInputProps()} />
                          {files?.length === 0 ? (
                            <p style={{ color: '#666', fontWeight: '500', display: 'flex', justifyContent: 'center', marginBottom: '0px' }}>Drop files here
                              to upload</p>
                          ) : (
                            <>
                              {thumbs}
                            </>
                          )}
                        </div>
                      </div>
                      {error && (
                        <span style={{ color: 'red', marginTop: '2px', fontSize: '11px' }}>{error}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn"
                style={{ backgroundColor: 'rgb(93, 135, 255)', color: 'white' }}
                data-bs-dismiss="modal"
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ImageModal;