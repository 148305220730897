/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description ActionTypes and ActionCreator Function for plan-creation Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  PLAN_CREATION_REQUEST: 'PLAN_CREATION_REQUEST',
  PLAN_CREATION_SUCCESS: 'PLAN_CREATION_SUCCESS',
  PLAN_CREATION_FAILURE: 'PLAN_CREATION_FAILURE',

  GET_WEBSITE_REQUEST: 'GET_WEBSITE_REQUEST',
  GET_WEBSITE_SUCCESS: 'GET_WEBSITE_SUCCESS',
  GET_WEBSITE_FAILURE: 'GET_WEBSITE_FAILURE',

  UPDATE_REVIEW_SITE_PRICE_REQUEST: 'UPDATE_REVIEW_SITE_PRICE_REQUEST',
  UPDATE_REVIEW_SITE_PRICE_SUCCESS: 'UPDATE_REVIEW_SITE_PRICE_SUCCESS',
  UPDATE_REVIEW_SITE_PRICE_FAILURE: 'UPDATE_REVIEW_SITE_PRICE_FAILURE',

  GET_REVIEW_SITE_DISCOUNT_REQUEST: 'GET_REVIEW_SITE_DISCOUNT_REQUEST',
  GET_REVIEW_SITE_DISCOUNT_SUCCESS: 'GET_REVIEW_SITE_DISCOUNT_SUCCESS',
  GET_REVIEW_SITE_DISCOUNT_FAILURE: 'GET_REVIEW_SITE_DISCOUNT_FAILURE',

  DELETE_REVIEW_SITE_DISCOUNT_REQUEST: 'DELETE_REVIEW_SITE_DISCOUNT_REQUEST',
  DELETE_REVIEW_SITE_DISCOUNT_SUCCESS: 'DELETE_REVIEW_SITE_DISCOUNT_SUCCESS',
  DELETE_REVIEW_SITE_DISCOUNT_FAILURE: 'DELETE_REVIEW_SITE_DISCOUNT_FAILURE',

};

export const planCreationRequest = (planCreationData) => {
  return {
    type: ActionTypes.PLAN_CREATION_REQUEST,
    payload: planCreationData,
  };
};

export const getwebsiteRequest = () => {
  return {
    type: ActionTypes.GET_WEBSITE_REQUEST,
  };
};

export const updatereviewsitepriceRequest = (reviewsiteData) => {
  return {
    type: ActionTypes.UPDATE_REVIEW_SITE_PRICE_REQUEST,
    payload: reviewsiteData,
  };
};

export const getreviewsitediscountRequest = () => {
  return {
    type: ActionTypes.GET_REVIEW_SITE_DISCOUNT_REQUEST,
  };
};

export const deletereviewsitediscountRequest = (id) => {
  return {
    type: ActionTypes.DELETE_REVIEW_SITE_DISCOUNT_REQUEST,
    payload: id,
  };
};
