import { ActionTypes } from './ReviewSitesActions';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description reviewsitelisting Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store reviewsitelisting api response  
**/

const defaultState = {
  isReviewSiteListing: false,
};

const reviewsiteListingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REVIEWSITES_LISTING_REQUEST:
      return {
        ...state,
        isReviewSiteListing: true,
      };
    case ActionTypes.REVIEWSITES_LISTING_SUCCESS:
      return {
        ...state,
        data: action.data,
        isReviewSiteListing: false,
      };
    case ActionTypes.REVIEWSITES_LISTING_FAILURE:
      return {
        ...state,
        isReviewSiteListing: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default reviewsiteListingReducer;