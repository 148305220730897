import { ActionTypes } from './userActions';

/**
 * @author      Nandani.V.Patel
 * @date        16 Oct 2024
 * @description User Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store User api response  
**/

const defaultState = {
  isUser: false,
  isdeleteUser: false,
  deleteUser: undefined,
  data: undefined,
  isupdatestatus: false,
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_REQUEST:
      return {
        ...state,
        isUser: true,
      };
    case ActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        isUser: false,
      };
    case ActionTypes.GET_USER_FAILURE:
      return {
        ...state,
        isUser: false,
        error: action.error,
      };
    case ActionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        isdeleteUser: true,
      };
    case ActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUser: action.data,
        isdeleteUser: false,
      };
    case ActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        isdeleteUser: false,
        error: action.error,
      };
    case ActionTypes.UPDATE_USER_STATUS_REQUEST:
      return {
        ...state,
        isupdatestatus: true,
      };
    case ActionTypes.UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        userStatus: action.data,
        isupdatestatus: false,
      };
    case ActionTypes.UPDATE_USER_STATUS_FAILURE:
      return {
        ...state,
        isupdatestatus: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default userReducer;