import { ActionTypes } from './teamleaderActions';

/**
 * @author      Nandani.V.Patel
 * @date        18 Oct 2024
 * @description teamleaderlisting Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store teamleaderlisting api response  
**/

const defaultState = {
  isteamLeader: false,
  iswebsite: false,
};

const teamLeaderListingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.TEAM_LEADER_LISTING_REQUEST:
      return {
        ...state,
        isteamLeader: true,
      };
    case ActionTypes.TEAM_LEADER_LISTING_SUCCESS:
      return {
        ...state,
        data: action.data,
        isteamLeader: false,
      };
    case ActionTypes.TEAM_LEADER_LISTING_FAILURE:
      return {
        ...state,
        isteamLeader: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default teamLeaderListingReducer;