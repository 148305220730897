import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './reviewsitedetailsActions';
import { postRequestAPI } from '../../global/api';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description reviewsite  Saga and handling API response.
 * @param       reviewsite-details Request paylod
 * @response    reviewsite details  is success or fail.  
**/

function* reviewsitedetailSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/review-site-wise-company', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REVIEWSITES_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REVIEWSITES_DETAILS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REVIEWSITES_DETAILS_FAILURE, error: err?.response?.data });
  }
}

function* reviewwiseusersaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'api/admin/reviewsite-company-wise-userreview', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_FAILURE, error: err?.response?.data });
  }
}

function* watchReviewSiteDetailsRequest() {
  yield takeLatest(ActionTypes.REVIEWSITES_DETAILS_REQUEST, reviewsitedetailSaga);
  yield takeLatest(ActionTypes.REVIEW_SITE_WISE_USER_DETAILS_REQUEST, reviewwiseusersaga);
}

function* reviewsiteDetailsSaga() {
  yield fork(watchReviewSiteDetailsRequest);
}

export default reviewsiteDetailsSaga;