import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import * as ReviewlistingActions from './ReviewSitesActions';
import styles from './styles.module.scss';
import { REVIEWSITES } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        21 Oct 2024
 * @description ReviewSites listing
 * @param
 * @response 
**/

const ReviewSites = (props) => {
  const { reviewsite, actions } = props;
  const navigate = useNavigate();
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [reviewsites, setreviewsites] = useState('');

  useEffect(() => {
    if (firstInit) {
      actions.reviewsitelistingRequest({ page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof reviewsite?.data !== 'undefined' && !reviewsite?.isReviewSiteListing) {
      setreviewsites(reviewsite?.data?.data);
    }
  }, [reviewsite?.data, reviewsite?.isReviewSiteListing]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: 'Review site',
      selector: row => row?.review_site_tbl_name,
      sortable: true,
    },
    {
      name: 'Review price',
      selector: row => `RS.  ${row?.review_site_tbl_review_price || 0}`,
      sortable: true,
    },
    {
      name: "Action",
      minWidth: '50px',
      maxWidth: '140px',
      center: 'yes',
      cell: (row) => (
        <>
          <div
            title='View'
            className={` ${styles.iconStyle} action-btn`}
            style={{ color: '#5D87FF' }}
            onClick={() => {
              const encodedName = encodeURIComponent(row?.review_site_tbl_name); // Encode the name for URL
              navigate(`${REVIEWSITES}?reviewsite=${encodedName}&id=${row?.id}`); // Construct the URL
            }}
          >
            <i className="ti ti-eye fs-5" />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Review Sites</h3>
      </div>
      <DataTable
        columns={columns}
        data={reviewsites}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={reviewsite?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  reviewsite: state.reviewsite,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ReviewlistingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSites);