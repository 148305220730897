import { toast } from 'react-toastify';

/**
 * @author      Nandani.V.Patel
 * @date        4 Oct 2024
 * @description Toast function for Notification message and type of message.
 * @param
 * @response 
**/

export const notification = (message, type) => {
  toast(message, {
    type: type,
  });
};

