import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import ImageModal from '../ImageModal';
import * as WithdrawalActions from './withdrawalActions';

/**
 * @author      Nandani.V.Patel
 * @date        12 Nov 2024
 * @description Withdrawal Request
 * @param
 * @response 
**/

const Withdrawal = (props) => {
  const { withdrawal, actions } = props;
  const [firstInit, setFirstInit] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [withdrawaldata, setwithdrawaldata] = useState('');

  useEffect(() => {
    if (firstInit) {
      actions.getWithdrawalRequest({ page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof withdrawal?.data !== 'undefined' && !withdrawal?.iswithdrawal) {
      setwithdrawaldata(withdrawal?.data?.data);
    }
  }, [withdrawal?.data, withdrawal?.iswithdrawal]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  // Close Image modal function
  const handleImageModalClose = () => {
    setIsImageModalOpen(false);
  };

  const handleApprovedClick = (data) => {
    setImageSrc(data);
    setIsImageModalOpen(true);
  };

  const columns = [
    {
      name: 'User Name',
      selector: row => row.user_name,
      sortable: true,
    },
    {
      name: 'Bank Name',
      selector: row => row.bank_name,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.withdrawal_amount,
      sortable: true,
    },
    {
      name: "Action",
      center: 'yes',
      cell: (row) => (
        <>
          <div className="form-check py-2 form-switch">
            <button
              type="button"
              title='Update Status'
              className="btn bg-primary-subtle text-primary "
              onClick={() => handleApprovedClick(row.id)}
            >
              Approved
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="card-header text-bg-primary mb-3">
        <h3 className="mb-0 text-white" style={{ fontSize: '18px' }}>Withdrawal Request</h3>
      </div>
      <DataTable
        columns={columns}
        data={withdrawaldata}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={withdrawal?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
      {isImageModalOpen && <ImageModal onClose={handleImageModalClose} imageSrc={imageSrc} />}
    </>
  )
}

const mapStateToProps = (state) => ({
  withdrawal: state.withdrawal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(WithdrawalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);